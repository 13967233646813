@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/lato-regular.eot');
  src: url('../fonts/Lato/lato-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato/lato-regular.woff2') format('woff2'),
    url('../fonts/Lato/lato-regular.woff') format('woff'),
    url('../fonts/Lato/lato-regular.ttf') format('truetype'),
    url('../fonts/Lato/lato-regular.svg#latoregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/lato-bold.eot');
  src: url('../fonts/Lato/lato-bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato/lato-bold.woff2') format('woff2'),
    url('../fonts/Lato/lato-bold.woff') format('woff'),
    url('../fonts/Lato/lato-bold.ttf') format('truetype'),
    url('../fonts/Lato/lato-bold.svg#latobold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-regular.eot');
  src: url('../fonts/Poppins/poppins-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/Poppins/poppins-regular.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-regular.woff') format('woff'),
    url('../fonts/Poppins/poppins-regular.ttf') format('truetype'),
    url('../fonts/Poppins/poppins-regular.svg#poppinsregular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-light.eot');
  src: url('../fonts/Poppins/poppins-light.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/Poppins/poppins-light.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-light.woff') format('woff'),
    url('../fonts/Poppins/poppins-light.ttf') format('truetype'),
    url('../fonts/Poppins/poppins-light.svg#poppinslight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-medium.eot');
  src: url('../fonts/Poppins/poppins-medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/Poppins/poppins-medium.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-medium.woff') format('woff'),
    url('../fonts/Poppins/poppins-medium.ttf') format('truetype'),
    url('../fonts/Poppins/poppins-medium.svg#poppinsmedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-semibold.eot');
  src: url('../fonts/Poppins/poppins-semibold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/Poppins/poppins-semibold.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-semibold.woff') format('woff'),
    url('../fonts/Poppins/poppins-semibold.ttf') format('truetype'),
    url('../fonts/Poppins/poppins-semibold.svg#poppinssemibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
