.ad
  //width: 320px
  //max-width: 90%
  //height: 50px
  @apply w-full
  height: auto
  @apply mb-5 md:mb-10
  @apply mx-auto
  //@apply bg-gray-200
  @apply text-center
  @media screen and (min-width: 640px)
    //width: 728px
    //height: 90px