.sidebar
  @apply max-w-sidebar w-full
  @apply hidden lg:block
  @apply pl-8
  @apply text-sm
  @screen xl
      @apply text-base

  h3.headline
    @apply text-sm
    @screen xl
      @apply text-lg
  