.form
  .label
    @apply text-sm
    a
      text-decoration: underline
  label + span
    @apply text-xs
    @apply text-primary
    @apply mt-1
    @apply flex
    @apply w-full
    @apply py-2 px-3
    @apply bg-primary bg-opacity-10
    @apply rounded
    &:before
      content: ''
      @apply text-primary
      background: no-repeat center / contain  url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-corner-left-up'%3E%3Cpolyline points='14 9 9 4 4 9'%3E%3C/polyline%3E%3Cpath d='M20 20h-7a4 4 0 0 1-4-4V4'%3E%3C/path%3E%3C/svg%3E")
      @apply w-4 h-4
      @apply mr-2
  select,input
    &[type="text"],
    &[type="password"],
    &[type="date"],
    &[type="email"]
      @apply border
      @apply border-gray-300
      @apply rounded
      @apply bg-white
      transition: all .3s ease
      &:focus
        outline: none
        @apply border-blue-dark
        @apply bg-white
        box-shadow: none

    &[type="checkbox"]
      @apply text-primary
      @apply focus:ring-0 focus:outline-none focus:shadow-none focus:ring-offset-0
      @apply rounded
      @apply border
      @apply border-gray-300
      width: 1.5em
      height: 1.5em
  
  textarea
    @apply border-gray-300
    @apply rounded
    transition: all .3s ease
    &:focus
      outline: none
      @apply border-blue-dark
      box-shadow: none

  .error
    @apply border-primary border
    @apply p-3
    @apply mb-3
    @apply rounded
    @apply text-sm
    padding-left: 4em
    position: relative
    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      background: no-repeat center / 1.5em auto url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E")
      width: 3em
      height: 100%
      @apply bg-primary

.ligen-filter > div:first-child
  grid-column: span 2 / span 2

  @media screen and (min-width: 640px)
    grid-column: span 1 / span 1
