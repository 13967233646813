.schedule-list-item-additional
  @apply w-full
  @apply text-xs
  @apply mb-2
  overflow: hidden
  span, a
    @apply inline-block
    @apply bg-gray-200
    @apply py-1 px-2
    @apply rounded
    @apply truncate
    @apply mr-2 last:mr-0
.schedule-list-item
  @apply flex items-center
  @apply pb-4
  @apply transition duration-300
  @apply relative
  .schedule-list-item-teams
    //@apply w-8/12
    @apply flex-1
    @apply  pr-3
    @apply overflow-hidden
    .schedule-list-item-team-wrapper
      @apply flex items-center
      .schedule-list-item-logo-wrapper
        @apply w-5 min-w-5
        @apply h-5
        @apply inline-block
        @apply mr-2
      .schedule-list-item-team
        @apply truncate
        display: inline-block
  .schedule-list-item-starts-at
    //@apply w-4/12
    text-align: center
    min-width: 51px
    @apply border-l border-gray-300
    @apply pl-4 md:px-6
    .schedule-list-item-date,
    .schedule-list-item-time
      @apply whitespace-nowrap
    .schedule-list-item-date
      font-size: 0.9em

    &.post,
    &.live
      @apply border-0
      @apply w-auto
      @apply text-right
      @apply flex flex-col
      @apply bg-blue-dark text-white
      @apply rounded
      @apply p-0
      .schedule-list-item-badge
        @apply rounded
        @apply py-2 px-4
        @apply border-2 border-white
      .schedule-list-item-score
        @apply py-2 px-4 h-10
        &:first-child
          @apply border-b-2 border-white

    &.live
      @apply relative
      animation: pulsing 2s infinite
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)
      @apply text-primary
      @apply bg-primary text-white

      @keyframes pulsing
        0%
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7)

        70%
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)

        100%
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)
