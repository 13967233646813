%border-left
  content: ''
  height: 100%
  height: calc(100% + 1rem)
  width: 1px
  position: absolute
  left: 1rem
  @apply top-5
  border-left: 2px dotted theme('colors.gray.400')
  transform: translateX(-50%)
  z-index: 2

%point-left
  content: ''
  width: 9px
  height: 9px
  position: absolute
  left: 1rem
  @apply top-4
  transform: translateX(-50%)
  @apply bg-primary
  border-radius: 5rem
  z-index: 3
  border: theme('margin.1') solid #fff
  box-sizing: content-box

#tickaroo-liveblog

  .tik3-scoreboard-team a
    text-decoration: none

  ul li
    margin-bottom: 0

  .tik3-event-list
    max-width: 700px
    margin: 0 auto

  .tik3-scores .tik3-event-item-content-text h2,
  .tik3-ticker .tik3-event-item-content-text h2
    @apply text-xl

  .tik3-event-item-content-text
    @apply prose

  // SCOREBOARD
  .tik3-scoreboard-caption, h4.gameinfos
    color: $grey

  .gameinfos
    a
      //text-decoration: none
      @apply text-primary

  .tik3-scoreboard-state-timestamp
    span
      color: $grey

  .tik3-scoreboard-container
    @apply bg-gray-200
    @apply border-none
    @apply mb-10
    @apply py-5
    @extend .container-offset, .container
    .tik3-event-item-meta-timestamp-date + .tik3-event-item-meta-timestamp-time
      &:before
        content: '- '
  .tik3-event-item
    position: relative
    border-top: none
    margin-bottom: 1rem
    @apply bg-white 
    @apply sm:rounded-lg
    @apply pl-8
    @apply pr-4
    &:not(:last-of-type):before
      @extend %border-left
    &:after
      @extend %point-left
    &.tik3-event-item-with-icon
      @apply pl-10
      .tik3-flex-event-meta-state__time-empty
        min-height: 1em

  .tik3-section-headline
    position: relative
    margin: 0
    @apply py-4 md:py-6
    &:not(:first-child):before
      @extend %border-left
    h4
      @apply text-primary
      @apply p-0 m-0

  .tik3-event-item-meta
    @apply mb-1
    &.tik3-event-item-meta-empty
      @apply mb-0

    .tik3-flex-event-meta__state

      .tik3-flex-event-meta-state__time
        .tik3-event-item-meta-timestamp
          @apply font-semibold
          .tik3-event-item-meta-timestamp-date,
          .tik3-event-item-meta-timestamp-time
            display: block
          .tik3-event-item-meta-timestamp-date
            font-weight: 400
            @apply text-gray-400

      .tik3-event-item-icon
        position: absolute
        width: 2em
        height: 2em
        @apply top-2
        left: 1rem
        z-index: 4
        transform: translateX(-50%)
        @apply bg-white
        border-radius: 5rem
        @apply p-1
        display: flex
        img
          display: block


    // REPORTER
    .tik3-flex-event-meta-reporter,
    .tik3-flex-event-meta-reporter

      .tik3-flex-event-content-meta-reporter__image,
      .tik3-flex-event-content-meta-reporter__image
        order: 2
        margin: 0
        @apply ml-2

  // MEDIA
  .tik3-scores .tik3-event-item-content-media-item,
  .tik3-ticker .tik3-event-item-content-media-item
    display: flex
    .tik3-media-item-image
      img
        @apply rounded-lg
        width: 100%

  // WEB EMBED
  .tickaroo-web-embed-url-wrapper
    display: flex
    @apply flex-col sm:flex-row
    text-decoration: none
    transition: all .3s ease
    @apply rounded-lg
    .tickaroo-web-embed-url-thumb-wrapper
      background: none
      @apply mb-2 sm:mb-0
      @apply w-full sm:w-2/5 max-w-full
      .tickaroo-web-embed-url-thumb-container
        @apply rounded
      
    .tickaroo-web-embed-url-data
      @apply w-full sm:w-3/5
      @apply pl-0 sm:pl-4

  .tikjs-load-more
    @extend .btn, .btn-primary

  // SHARING
  .tik3-scores,
  .tik3-ticker
    .tik3-event-item-content-sharing
      display: none
    .tik3-event-item-content-sharing-list
      @apply pt-4
      .tik3-event-item-content-sharing
        display: inline-block
        margin: 0
        width: 1.3rem
        height: 1.3rem
        @apply mr-3
        a
          text-indent: 100%
          white-space: nowrap
          overflow: hidden
          width: 100%
          height: 100%
          position: relative
          padding: 0
          margin: 0
          &:before
            display: none
          &:after
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
        &.tik3-event-item-content-sharing-facebook a:after
          background: no-repeat center / contain url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23a3a3a3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-facebook'%3E%3Cpath d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z'%3E%3C/path%3E%3C/svg%3E")
        &.tik3-event-item-content-sharing-twitter a:after
          background: no-repeat center / contain url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23a3a3a3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-twitter'%3E%3Cpath d='M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z'%3E%3C/path%3E%3C/svg%3E")
        &.tik3-event-item-content-sharing-email a:after
          background: no-repeat center / contain url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23a3a3a3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mail'%3E%3Cpath d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'%3E%3C/path%3E%3Cpolyline points='22,6 12,13 2,6'%3E%3C/polyline%3E%3C/svg%3E")
      .tik3-event-item-content-sharing-close-items
        display: none

  // LINEUP
  #tik3-event-item-lineup-home
    @apply mt-20
    @apply border-t border-gray-200
  #tik3-event-item-lineup-home,
  #tik3-event-item-lineup-away
    .tik3-lineup-opponent-title,
    .tik3-lineup-opponent-title
      @apply font-bold
      @apply text-primary
    .tik3-lineup-group
      @apply py-2
      .tik3-lineup-group-title
        @apply font-bold

  // POWERED BY
  .tik3-powered-by
    max-width: 600px
    margin: 0 auto
    border: none

// OVERLAY
.rooverlay-wrapper.rooverlay-type-image
  .rooverlay-overlay
    background-color: rgba(0,0,0,0.95)
  .rooverlay-content
    @apply rounded-lg
    overflow: hidden
    .rooverlay-description
      transition: transform .3s ease
      transform: translateY(100%)
      display: block
      @apply bg-primary bg-opacity-80

    &:hover
      .rooverlay-description
        transform: translateY(0)


.preview-box
  @apply border border-gray-300
  //border-color: $dark
  @apply rounded
  @apply py-4 px-4 xl:py-5 xl:px-6
  @apply relative

  .sign
    @apply absolute
    @apply -top-3 -right-2
    @apply py-0.5 px-2
    @apply rounded
    @apply uppercase
    @apply font-semibold
    @apply text-sm

    &.live-sign
      background-color: $primary
      color: $white

    &.preview-sign, &.info-sign
      background-color: $bg-body
      color: $dark

  .preview-box-team
    @apply whitespace-nowrap
    @apply truncate
    @apply pr-2

  .preview-box-number
    @apply font-semibold 
    @apply text-lg md:text-xl
    @apply text-right
    @apply pl-2

  &.live-box
    .preview-box-number
      color: $primary 

  .preview-box-date
    @apply font-semibold 
    @apply text-lg md:text-xl
    @apply text-center
    @apply leading-8
    @apply pl-2

.container-ticker
  @apply w-full max-w-container
  @apply mx-auto
  @apply mb-5 md:mb-10


.ticker-nav
  .ticker-nav-item
    min-width: fit-content
    @apply inline-block
    @apply py-2 px-3
    @apply rounded
    @apply truncate

.tik3-ticker 
  .tik3-scoreboard-team 
    a 
      font-size: 1rem
      @screen sm
        font-size: 1.2rem
      @screen md
        font-size: 1.3rem

  .tik3-scoreboard-simple 
    .tik3-scoreboard-main-score
      font-size: 1.3rem
      @screen sm
        font-size: 1.5rem
      @screen md
        font-size: 1.7em
