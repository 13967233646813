.pagination
  @apply py-4
  @apply flex items-baseline justify-center

  .pagination-items
    @apply px-2

  .pagination-item
    @apply py-4 px-4 xl:py-5 xl:px-6
    @apply relative
    @apply w-10 h-10
    @apply inline-block
    @apply cursor-pointer
    border-bottom: 2px solid transparent

    &:not(.disabled)
      @apply hover:text-primary

    &.pagination-item-empty
      @apply bg-transparent
      @apply cursor-default

    &.active
      @apply text-primary
      border-bottom: 2px solid $primary

    &.disabled
      cursor: default

    span
      @apply absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
