//SPACING
.horizontal-child-spacing-2
  > *:not(:last-child)
    @apply mr-2

.vertical-child-spacing-2
  > *:not(:last-child)
    @apply mb-2

//HEADLINES
h2
  color: $primary
  @apply font-semibold
  @apply mb-3
  @apply uppercase

h3
  @apply font-semibold
  @apply mb-3
  @apply uppercase


.main-menu-backdrop
  animation: .2s menu-fade-in

.main-menu
  animation: .2s main-menu-in

.sub-menu-container
  animation: .2s menu-fade-in
.sub-menu
  animation: .2s sub-menu-in
  @screen md
    animation-name: main-menu-in


@keyframes main-menu-in
  0% 
    @apply opacity-0
    @apply -translate-x-10
  100% 
    @apply opacity-100
    @apply translate-x-0

@keyframes menu-fade-in
  0% 
    @apply opacity-0
  100% 
    @apply opacity-100

@keyframes sub-menu-in
  0% 
    @apply translate-x-10
  100% 
    @apply translate-x-0

:root
  --tabBarHeight: calc(4.5rem + max(env(safe-area-inset-bottom), 1rem))

.tab-bar
  box-shadow: 0 -2px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)