.progress
  @apply flex
  @apply h-3
  @apply overflow-hidden
  background-color: $bg-body
  @apply rounded

  .progress-bar
    background-color: $dark
    @apply flex
    @apply flex-col
    @apply justify-center
    @apply overflow-hidden
    @apply w-1/4

.statistics-nav
  margin-right: 0.3rem

  &:hover
    color: rgba(210, 50, 21)

  @media screen and (min-width: 550px)
    margin-right: 1.5rem

.statistics-nav-active
  position: relative
  @apply text-primary

  &:after
    position: absolute
    content: ''
    bottom: -10px
    left: 0
    height: 2px
    @apply bg-primary
    @apply w-full
    animation: activeState .3s ease-in-out

.statistics-nav-complete
  display: flex
  justify-content: space-between
  margin-bottom: 0.5rem
  @media screen and (min-width: 550px)
      display: block

th.sorted-asc
  @apply text-primary
th.sorted-desc
  @apply text-primary
th.sorted-desc .statistics-table .statistics-table-sort
  fill: #D23215
th.sorted-none .statistics-table .statistics-table-sort
  fill: #d8d8d8
  &:hover
    fill: #a1a1a1
th.sorted-none
  color: rgba(210, 50, 21, 0.75)
  &:hover
    @apply text-primary
    cursor: pointer

.spinner-statistics
  display: block
  margin: 1rem auto 0 auto
  width: 5rem
  height: auto


.statistics-box
  background-color: $bg-body
  @apply rounded
  @apply py-3 px-4 xl:py-4 xl:px-6
  @apply text-center
  @apply mx-1 mt-2

  .statistics-number
    @apply font-semibold
    @apply text-2xl md:text-4xl


  .statistics-title
    @apply text-xs md:text-sm
    @media screen and (min-width: 1023px) and (max-width: 1279px )
      font-size: 0.58rem

  .statistics-detail
    @apply text-xs
    @apply mt-2
    @media screen and (min-width: 1023px) and (max-width: 1279px )
      font-size: 0.58rem

  .statistics-graphic-goal
    @apply flex
    @apply h-2
    @apply overflow-hidden
    background-color: $primary
    @apply border
    border-color: $primary
    @apply rounded-sm
    @apply my-1.5

    .stripe-red, .stripe-white
      @apply flex
      @apply flex-col
      @apply justify-center
      @apply overflow-hidden
      @apply w-1/4

    .stripe-red
      background-color: $primary

    .stripe-white
      background-color: $white

  .statistics-graphic-card
    @apply rounded

    &.card-red
      background-color: $primary

    &.card-blue
      background-color: #062C80

    &.card-yellow
      background-color: $yellow

  .statistics-graphic-meter
    background-color: $primary
    color: $white
    @apply rounded
    @apply font-semibold
    @apply mt-1 mb-2 md:my-2

  .statistics-graphic-penalty
    background-color: $dark
    color: $white
    @apply rounded
    @apply font-semibold
    @apply mt-1 mb-2 md:my-2

.statistics-table
  @apply rounded
  width: auto

  .statistics-table-card
    @apply rounded
    width: 1.15rem
    height: 1.4rem
    margin-top: auto
    margin-bottom: auto

    &.card-red
      background-color: $primary

    &.card-blue
      background-color: #062C80

    &.card-yellow
      background-color: $yellow

  .statistics-table-penalty
    background-color: $dark
    color: $white
    @apply rounded
    @apply font-semibold
    @apply mt-2 mb-1 md:my-2
    width: 3rem
    max-height: 1.15rem
    text-align: center
    margin-top: auto
    margin-bottom: auto

  .statistics-table-penalty-strafe
    background-color: $dark
    color: $white
    @apply rounded
    @apply font-semibold
    @apply mt-2 mb-1 md:my-2
    width: 3rem
    max-height: 1.15rem
    text-align: center
    margin-top: auto
    margin-bottom: auto
    font-size: 0.7em

.statistic_table_header
  z-index: 999999


.statistics-table-sort
  width: 1rem
  height: auto
  margin-top: auto
  margin-bottom: auto
  margin-left: 0.1rem
  display: inline-block
  text-decoration: none
  margin-left: 0.3rem

.statistic_table_sticky
  position: sticky
  left: 0
  top: 0
  background-color: white 
  min-width: 9rem
  padding-top: 1rem

.table thead tr th.statistic_table_sticky_header
  position: sticky
  left: 0
  top: 0
  background-color: white 
  min-width: 9rem
  padding-top: 1rem
  z-index: 5

.table thead tr th.statistic_table_sticky
  z-index: 9999999

.statistic_number
  min-width: 2rem
  padding-top: 0.5rem
  padding-bottom: 0.5rem
  float: left
  height: fit-content

.statistic_number_header
  width: 2rem
  float: left

.statistic_benennung
  padding-top: 0.5rem
  padding-bottom: 0.5rem
  white-space: normal

.info-box
  @apply bg-primary
  @apply p-4
  @apply text-white
  @apply inline-flex
  @apply rounded
  @apply mb-6

  a
    @apply relative
    &:hover
      color: $dark
