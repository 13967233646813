.map
  .marker
    cursor: pointer
    position: relative
    transform: translateY(-5px)
    transition: all 0.2s ease-in-out
  .marker .tip
    position: absolute
    bottom: 0
    width: 0
    height: 0
    border: 8px solid white
    border-radius: 0
    border-bottom-right-radius: 5px
    z-index: -1
    left: 50%
    transform: translateY(22%) translateX(-50%) rotate(45deg)
  .marker .pin
    position: relative
    max-height: 42px
    max-width: 42px
    border: 2px solid white
    border-radius: 0.25rem
    background-color: white
    justify-content: center
    align-items: center
    transform-origin: bottom
    transition: max-width 0.2s ease-in-out, max-height 0.2s ease-in-out
    .list-item
      transition: opacity 0.2s ease-in-out
      opacity: 0%
  .marker__selected
    transform: scale(1.2) translateY(-5px)
    transform-origin: 50% 100%
    z-index: 10000000
    .tip
      border-color: blue
    .pin
      max-width: 400px
      max-height: 150px
      border: 2px solid blue
      background-color: white
      .list-item
        opacity: 100%
  .marker__hovered
    transform: scale(1.2) translateY(-5px)
    transform-origin: 50% 100%
  .info-panel
    width: 80vw
    transform: translateX(-100%)
    transition: transform 0.2s ease-in-out
  .info-panel__selected
    transform: translateX(0%)
