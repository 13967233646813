$triangleWidth: 13px
$logoBorder: 0.25rem
$transition: 0.3s

/* TODO if map should stay revise sass code */

.verbandskarte
  font-size: 0.6rem
  @apply w-3/4
  @apply mx-auto
  @screen lg
    @apply w-10/12

  .verbaende-map
    @apply w-full
    @apply h-auto
    max-width: 665px
    margin: 0 auto

  .logo
    background: #fff
    @apply p-2
    @apply transition-all duration-300
    @apply inline-flex justify-between
    @apply shadow
    height: 45px
    @screen lg
      height: 35px
    @screen xl
      height: 55px

  .logo-wrapper
    @apply absolute
    @apply inline-flex content-start flex-col
    @apply no-underline
    color: initial
    max-width: 65px
    @screen lg
      max-width: 60px
    @screen xl
      max-width: 100px

    &:hover
      z-index: 2
      .logo
        background: theme('colors.gray.100')
        @apply shadow-lg
      &.pointer-bottom-left:after
        border-color: theme('colors.gray.100') transparent transparent transparent
      &.pointer-bottom-right:after
        border-color: transparent theme('colors.gray.100') transparent transparent
      &.pointer-top-left:after
        border-color: transparent transparent transparent theme('colors.gray.100')
      &.pointer-top-right:after
        border-color: transparent transparent theme('colors.gray.100') transparent

    &:after
      content: ''
      @apply w-0 h-0
      @apply block
      border-style: solid
      transition: border-color $transition

  /* Logo Position */

  .logo-Mecklenburg-Vorpommern
    left: 80%
    top: 12%
    @extend .pointer-bottom-left

  .logo-DHB
    left: 18.9%
    top: 45%
    @extend .pointer-top-left

  .logo-Schleswig-Holstein
    left: 34%
    top: 7%
    @extend .pointer-bottom-right

  .logo-Hamburg
    left: 46.5%
    top: 11%
    @extend .pointer-bottom-left

  .logo-Bremen
    left: 33%
    top: 18%
    @extend .pointer-bottom-left

  .logo-Niedersachsen
    left: 43.1%
    top: 30.2%
    @extend .pointer-bottom-left

  .logo-Sachsen-Anhalt
    left: 52%
    top: 38%
    max-width: 180px
    @extend .pointer-top-right

  .logo-Berlin
    left: 80%
    top: 26%
    @extend .pointer-bottom-left

  .logo-Sachsen
    left: 71%
    top: 42%
    @extend .pointer-bottom-left

  .logo-Thueringer
    left: 56.5%
    top: 52%
    @extend .pointer-top-left

  .logo-Bayern
    left: 62.2%
    top: 81.9%
    @extend .pointer-bottom-left

  .logo-Suedbaden
    left: 21.5%
    top: 84.3%
    @extend .pointer-bottom-left

  .logo-Pfalz
    left: 26.4%
    top: 67.5%
    @extend .pointer-bottom-left

  .logo-Saar
    left: 1%
    top: 67.5%
    @extend .pointer-bottom-right

  .logo-Handballbundesliga-Frauen
    left: 19.7%
    top: 40.6%
    opacity: .2
    @extend .pointer-bottom-left

  .logo-Hessen
    left: 32%
    top: 57%
    @extend .pointer-bottom-left

  .logo-Niederrhein
    left: 1%
    top: 43%
    @extend .pointer-bottom-right

  .logo-Nordrhein
    left: 1%
    top: 43%
    @extend .pointer-bottom-right

  .logo-Rheinhessen
    left: 14%
    top: 60%
    @extend .pointer-bottom-right

  .logo-Westfalen
    left: 18%
    top: 37%
    @extend .pointer-bottom-left

  .logo-Mittelrhein
    left: 2%
    top: 52%
    @extend .pointer-top-right

  .logo-Rheinland
    left: 18%
    top: 53%
    @extend .pointer-bottom-left

  .logo-LIQUI-MOLY-Handball-BL
    left: 0%
    top: 54%
    opacity: .2
    @extend .pointer-top-right

  .logo-Baden
    left: 16%
    top: 77%
    @extend .pointer-top-right

  .logo-Brandenburg
    left: 77%
    top: 34%
    @extend .pointer-top-left

  .logo-Wuerttemberg
    left: 36%
    top: 74%
    @extend .pointer-bottom-left

  @media lg
    .logo-wrapper:after
      border-width: 15px 15px 0 0

.pointer-bottom-left
  &:after
    border-width: $triangleWidth $triangleWidth 0 0
    border-color: #ffffff transparent transparent transparent
  .logo
    border-radius: $logoBorder $logoBorder $logoBorder 0


.pointer-bottom-right
  &:after
    border-width: 0 $triangleWidth $triangleWidth 0
    border-color: transparent #ffffff transparent transparent
    align-self: flex-end
  .logo
    border-radius: $logoBorder $logoBorder 0 $logoBorder


.pointer-top-left
  flex-direction: column-reverse
  &:after
    border-width: $triangleWidth 0 0 $triangleWidth
    border-color: transparent transparent transparent #ffffff
  .logo
    border-radius: 0 $logoBorder $logoBorder $logoBorder


.pointer-top-right
  flex-direction: column-reverse
  &:after
    border-width: 0 0 $triangleWidth $triangleWidth
    border-color: transparent transparent #ffffff transparent
    align-self: flex-end
  .logo
    border-radius: $logoBorder 0 $logoBorder $logoBorder