.list-item
  @apply bg-white
  @apply md:border border-gray-200
  @apply transition-colors transition-shadow duration-300
  @apply flex items-center
  @apply md:rounded
  @apply py-3 pr-3 md:p-3
  @apply relative
  @apply hover:shadow-lg

  .list-item-content
    @apply flex items-center
    @apply w-11/12

    .list-item-logo
      @apply w-16 h-10
      @apply flex-shrink-0
      @apply mr-3

    .list-item-text-wrapper
      @apply overflow-hidden

      .list-item-title
        //@apply truncate

      .list-item-text
        @apply text-sm text-gray-500

  .list-item-icon-wrapper
    @apply flex-shrink-0
    @apply text-right
    @apply w-1/12

    .list-item-icon
      @apply inline-block
      @apply transition-transform duration-300

  .list-item-remove-icon
    @apply absolute 
    @apply cursor-pointer
    @apply flex items-center justify-center
    @apply w-5 h-5
    @apply bg-primary hover:bg-primary-dark
    @apply transition-colors
    @apply rounded-full
    @apply -top-2 -left-2
    @apply text-white

  &:hover
    @apply text-bodyColor
    .list-item-icon-wrapper
      .list-item-icon
        transform: translateX(-40%)

.list-item-with-button
  @apply relative
  
  .list-item-buttons
    @apply absolute
    @apply right-11 top-1/2
    @apply transform -translate-y-1/2
    @apply flex space-x-2