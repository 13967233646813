.table
  @apply text-xs sm:text-base
  width: 100%
  max-width: 100%
  border-collapse: separate
  border-spacing: 0
  tr
    th
      font-weight: 600
      @apply border-b-2 border-primary
      position: sticky
      top: 0
      z-index: 1
      @apply bg-white
    td
      @apply border-b border-gray-200
      &.numeric
        font-family: 'Lato'
        font-variant-numeric: tabular-nums
    th, td
      @apply py-2 px-3
    &:nth-child(4),
    &:nth-last-child(5)
      td
       // @apply border-gray-900
    &:last-child
      td
        border: none

  .truncate
    width: 100%
    max-width: 0

.crosstable
  @apply text-xs sm:text-base
  min-width: 100%
  table-layout: fixed
  border-collapse: separate
  border-spacing: 0
  tr
    th
      font-weight: 600
      @apply border-primary
    td
      border-bottom: 1px solid
      border-right: 1px solid
      @apply border-gray-200
    th, td
      @apply p-2
  thead
    tr
      th
        @apply border-b-2
        position: sticky
        // TODO [css]
        // sticky does not work here. might be because of the overflow wrapper around the table but can't figure it out for now. left <th> sticky is working and more important though.
        // working example:  https://css-tricks.com/a-table-with-both-a-sticky-header-and-a-sticky-first-column/
        top: 0
        z-index: 1
        @apply bg-white
        &:first-child
          @apply border-r-2
          z-index: 3
          left: 0
  tbody
    tr
      th
        @apply border-r-2
        position: sticky
        left: 0
        z-index: 1
        @apply bg-white
      td
        font-variant: common-ligatures tabular-nums
        &.numeric
          font-family: 'Lato'
          font-variant-numeric: tabular-nums

table
  .truncate
    width: 100%
    max-width: 0