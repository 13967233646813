@tailwind base
@tailwind components
@tailwind utilities
@import 'variables'
@import '~nprogress/nprogress.css'
@import 'fonts'

*
  margin: 0
  box-sizing: border-box

html,
body
  padding: 0
  margin: 0
  font-family: 'Poppins', sans-serif
  font-weight: 400
  font-size: 15px
  @apply h-full
  @apply bg-body
  @apply text-bodyColor

  @screen md
    font-size: 16px

body.showing-menu
  overflow: hidden !important


.selectable-tooltip
  color: white
  user-select: text !important


.app
  @apply min-h-screen
  @apply relative
  @apply pt-32 lg:pt-[9.5rem]
  // @apply pb-80 md:pb-52 lg:pb-80
  @apply pb-[calc(16rem+var(--tabBarHeight))]
  @apply md:pb-[calc(9rem+var(--tabBarHeight))]
  @apply lg:pb-80

  .map
    .marker
      .pin
        @apply flex items-center
        padding:2px

        img
          @apply object-contain

    .marker__selected
      .pin
        border-color: #ffffff

      .tip
        border-color: #ffffff











#cmpboxrecall
  @apply bottom-[calc(1rem+var(--tabBarHeight))]
  // @media (max-width: 768px)
  @screen lg
    @apply bottom-4

.content-sidebar
  @apply flex
  @apply justify-center
  margin: 0 auto
  max-width: 100%
  @screen lg
    max-width: 990px
  @screen xl
    max-width: 1200px
  &.content-full
    .content
      @apply max-w-full w-full

.content
  @apply w-full max-w-full lg:max-w-3/4
  @apply overflow-x-hidden
  // TODO temporary fix
  min-height: 580px


  //@apply max-w-container w-full
  // REMOVING THE MAX-WIDTH PREVENTS <MoreGames /> ON THE OFFICIAL TICKER PAGE FROM EXPANDING THE CONTENT AND SQUISHING THE SIDEBAR. I DID NOT NOTICE ANY INFLUENCE ON OTHER PAGES SO FAR. - Flo
  //@apply w-full

.container
  @apply w-full max-w-container
  @apply mx-auto
  @apply mb-5 md:mb-10
  @apply px-4 md:px-8

  // You cannot `@apply` the `mb-0` utility here because it creates a circular dependency.
  // &.mb-0
  //   margin-bottom: 0
  &.rounded-b-none
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0

  .container-offset
    @apply -mx-4 md:-mx-8
    width: auto
  .container-offset-top
    @apply -mt-6 md:-mt-8
  .container-offset-bottom
    @apply -mb-6 md:-mb-8

.container-white
  @extend .container
  @apply bg-white
  @apply shadow
  @apply py-6 md:py-8
  @apply lg:rounded
  &.container-sm
    @apply py-4

.container-white-wide
  @extend .container-white
  @apply max-w-none

.container-white-connect
  @apply mb-0
  @apply rounded-b-none
  @apply z-10
  & + .container-white
    @apply rounded-t-none


.container-white-py-0
    @extend .container
    @apply bg-white
    @apply shadow

.container-px-0
  @apply w-full max-w-container
  @apply mx-auto

.container-white-pb-0
  @extend .container-white
  @apply pb-0

.container-subnav
  @extend .container-white-pb-0

.container-breadcrumbs
  @apply w-full max-w-container
  @apply mx-auto mb-4
  @apply px-6 md:px-8
  @apply whitespace-nowrap
  @apply truncate

.container-dark
  @extend .container
  @apply text-white
  @apply py-6 md:py-8
  @apply bg-blue-dark
  @apply lg:rounded

.container-myhandball
  @extend .container
  @apply text-white
  @apply py-6 md:py-8
  @apply lg:rounded
  background-image: url(/img/banner/handball_net_mein_handball_backgound.webp)
  @apply bg-top bg-cover

.container-light
  background-color: $bg-body

.container-padding-wide
  @apply w-full
  @apply px-4 md:px-8



.text-grey
  color: $grey

a
  color: inherit
  @apply transition-colors md:hover:text-primary
  &:focus
    @apply outline-none
  &:focus-visible
    @apply outline-none
  &.disabled
    cursor: auto
    &:hover
      color: inherit

.headline
  &.headline-border
    @apply border-b
    @apply border-gray-300

.corner
  position: relative
  padding: 15px 0 15px 15px
  $corner-br: 2rem
  $corner-w: 8px
  $corner-h: 38px
  &:before,
  &:after
    position: absolute
    content: ''
    top: 0
    left: 0
    @apply bg-primary
  &:before
    width: $corner-h
    height: $corner-w
    border-top-right-radius: $corner-br
  &:after
    width: $corner-w
    height: $corner-h
    border-bottom-left-radius: $corner-br

.image
  img
    width: 100%
    height: 100%
    @apply text-xs
  &.image-square
    position: relative
    img
      width: 100%
      height: 100%
      position: absolute
    &::after
      content: ''
      display: block
      padding-bottom: 100%
  &.image-16\:9
    position: relative
    img
      position: absolute
      width: 100%
      height: 100%
    &::after
      content: ''
      display: block
      padding-bottom: 56.25%

.youtube-container
  position: relative
  padding-bottom: 56.25%
  padding-top: 0
  height: 0
  overflow: hidden

  iframe, object, embed
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.link
  &.link-icon
    svg
      @apply inline h-4 w-auto ml-2
      margin-top: -0.2rem

ul
  li
    @apply mb-1

h1
  @apply uppercase


.fade-bottom
  @apply relative
  &::after
    content: ''
    @apply absolute
    @apply bottom-0 left-0 right-0
    @apply bg-gradient-to-t from-white
    @apply w-full h-28

.smooth-transition
  z-index: 4
  position: relative

.scrollbar-x-none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

@import 'schedule'
@import 'buttons'
@import 'overlay'
@import 'nav'
@import 'basic'
@import 'layout'
@import 'text'
@import 'tables'
@import 'header'
@import 'footer'
@import 'ad'
@import 'dropdown'
@import 'ticker'
@import 'tik3'
@import 'tik4'
@import 'squad'
@import 'form'
@import 'widgets'
@import 'list-item'
@import 'pagination'
@import 'search'
@import 'sidebar'
@import 'statistics'
@import 'news'
@import 'map'
@import 'error'
@import 'scroll-to-top'
@import 'scoreboard'
@import 'videos'
@import 'app-update-info'
@import 'my-team-summary'
@import 'verbaende'
