// VIEW
.view-vertical
  @apply flex
  @apply flex-col

.view-horizontal
  @apply flex
  @apply flex-row


@layer utilities
  .min-w-0
    min-width: 0