header
  background-color: $light
  @apply z-50
  @apply w-full
  @apply top-0
  @apply fixed
  @apply shadow-md
  .header-desktop
    @apply hidden
    @apply lg:block

.nav-item
  color: $dark
  @apply font-semibold

.link
  &.active
    @apply text-primary


:not(.mobile-menu-content)
  .nav-items
    .dropdown-wrapper
      @apply text-bodyColor

      span
        @apply py-6

    .link, span
      @apply relative
      @apply hover:text-primary
      @apply transition-colors duration-200

      &.active
        position: relative
        @apply text-primary

        &:after
          position: absolute
          content: ''
          bottom: -10px
          left: 0
          height: 2px
          @apply bg-primary
          @apply w-full
          animation: activeState .3s ease-in-out

.mobile-menu-background
  display: flex
  justify-content: flex-end
  width: 100vw
  height: 100vh
  position: fixed
  top: 0
  // Has to be more than 100% since there is padding around triggering icon
  right: -120%
  z-index: 999
  transition: right .5s ease-in-out
  &.open
    right: 0

.mobile-menu-background-overlay
  display: flex
  justify-content: flex-end
  width: 100vw
  height: 100vh
  position: fixed
  right: 0
  // Has to be more than 100% since there is padding around triggering icon
  bottom: -120%
  opacity: 0.95
  z-index: 100
  transition: bottom .5s ease-in-out
  &.open
    bottom: 0

.mobile-menu-content
  @apply bg-white
  width: 85%
  z-index: 200
  // Enables scrolling inside of menu if content is longer than viewport
  overflow-y: auto
  a
    &.active
      color: $primary

.aok
  color: $green

.active
  @apply font-semibold

header
  .dropdown-wrapper
    .dropdown-items
      bottom: theme('margin[2]')
      @apply right-auto
      left: 0
      @apply normal-case
      box-shadow: 0 0 15px 0 rgba(0,0,0,0.1)

      .dropdown-item
        @apply text-center

      div
        @apply focus:outline-none

      &:before
        content: '▲'
        @apply text-white
        text-shadow: 0 -1px 1px rgba(0,0,0,0.1)
        @apply absolute top-0
        left: 50%
        transform: translate(calc(-50% - 2rem), -100%)
        line-height: .7

  button
    @apply focus:outline-none

@keyframes activeState
  0%
    width: 0
  100%
    width: 100%
