/* styling taken from a tailwind example and only temporary - will change in the future */
.btn-dropdown
  @apply inline-flex
  @apply items-center
  @apply space-x-2
  @apply transition
  @apply duration-150
  @apply ease-in-out
  @apply hover:text-gray-500

.dropdown-wrapper
  position: relative
  text-align: right
  @apply inline-flex

  .smooth-transition
    @apply absolute
    @apply left-0 bottom-0
    @apply transform translate-x-full translate-y-full
    @apply origin-top-right


  .dropdown-items
    @apply absolute
    @apply w-56 md:w-96
    @apply mt-2
    @apply bg-white
    @apply divide-y
    @apply divide-gray-100
    @apply rounded-md
    @apply shadow-lg
    @apply outline-none
    @apply bg-white
    z-index: 2
    max-height: 20rem
    overflow-y: auto

  &.dropdown-sm
    .dropdown-items
      @apply w-56 md:w-56


  .dropdown-item
    @apply flex
    @apply justify-between
    @apply w-full
    @apply px-4 py-2
    @apply text-sm
    @apply leading-5
    @apply text-left
    @apply hover:text-primary
    @apply transition-colors duration-300

.dropdown-left
  .dropdown-wrapper
    .smooth-transition
      @apply right-0 left-auto

.dropdown-right-sm-left
  .dropdown-wrapper
    .dropdown-items
      @apply right-0
      @apply left-auto
      @screen sm
        @apply left-0
        @apply right-auto

.dropdown-left-sm-right
  .dropdown-wrapper
    .dropdown-items
      @apply left-0
      @apply right-auto
      @screen sm
        @apply right-0
        @apply left-auto


/* Filter */
.filter-dropdown
  &.dropdown-wrapper
    @apply text-left

.abc-filter
  @apply -ml-1
  @screen lg
    @apply -ml-2

  a
    @apply py-0.5 px-0.5
    @screen lg
      @apply py-1 px-1

    &:hover, &.active
      @apply text-white
      background-color: $primary


