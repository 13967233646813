

.search
  @apply bg-white
  @apply rounded
  @apply border
  @apply h-full
  @apply px-4
  @apply py-2
  @apply mb-4
  @apply flex items-start
  @apply relative

  input
    @apply border-0
    @apply w-full
    @apply p-0

    &:focus-visible,&:focus
      @apply border-0
      @apply outline-none
      box-shadow: none !important

header
  .search
    @apply border-none
    @apply px-0 pt-0 pb-2
    @apply mb-0
    @apply rounded-none
    border-bottom: solid 2px $dark

    input
      @apply border-0
      @apply w-16
      @apply p-0
      @apply uppercase
      @apply font-medium
      @apply text-right
      &::placeholder
        color: $dark

  .overlay-content
    .search
      @apply border-none
      border-bottom: solid 2px $dark
      @apply rounded-t
      input
        @apply w-full
        @apply font-normal
        @apply text-left
        @apply normal-case
        border: none

  // .mobile-search-nav
  //   .search
  //     @apply px-0 py-0
  //     @apply border-none
  //     input
  //       @apply hidden lg:flex

.mobile-search
  .search
    @media screen and (max-width: 1023px)
      @apply bg-transparent
      @apply p-0
      @apply border-none
    input
      @apply hidden lg:flex

.overlay
  .search
    @apply mx-0
    @apply px-4
    @apply py-2
    @apply mb-4
    @apply bg-white
    @apply border-none
    border-bottom: solid 2px $dark
    @apply rounded-none
    @apply rounded-t
    input
      @apply flex
      &::placeholder
        color: $dark

header
  // .mobile-search-nav
  //   .overlay
  //     .search
  //       @apply mx-0
  //       @apply px-4
  //       @apply py-2
  //       @apply mb-4
  //       @apply bg-white
  //       @apply border-none
  //       border-bottom: solid 2px $dark
  //       @apply rounded-none
  //       @apply rounded-t
  //       input
  //         @apply flex
  //         &::placeholder
  //           color: $dark


.content
  .search
    @apply mb-0
    @apply flex
    &:not(.search-full)
      @apply w-40
    input
      @apply uppercase
      border: none

  .overlay
    .search
      @apply w-full
      @apply mb-4
      @apply flex
