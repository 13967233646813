.video-details
  color: $white

  img
    @apply w-full

  .md\:prose
      @apply no-underline
      @apply text-white
      @apply font-normal

      h1
        @apply text-white

      a
        @apply text-white
        text-decoration: none

        &:hover
          color: $primary

  iframe
    @apply mx-auto 
    @apply max-w-full

  .kg-card
    @apply w-full h-full
    iframe
      @apply h-full w-full