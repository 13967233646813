.error
  @apply flex items-center
  @apply mb-4
  @apply shadow-lg
  @apply rounded
  @apply overflow-hidden

  .error-icon
    @apply bg-primary
    @apply p-4
    @apply text-white

  .error-text
    @apply pl-4
    @apply inline-flex items-center