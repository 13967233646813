// overwriting default colors
.tik4.tik4 
  --t4-primary--l: theme('colors.primary.DEFAULT')
  --t4-secondary--l: theme('colors.primary.DEFAULT')
  --t4-txt--l: theme('colors.bodyColor')
  --t4-muted--l: theme('colors.gray.400')
  --t4-bg--l: theme('colors.white')
  // --t4-ev-h-bg--l:
  // --t4-ev-p-bg--l:

  --t4-tl-style: dotted
  --t4-tl-w: 2px

.tik4-live.tik4-live
  margin-top: var(--t4-sp-8)

.tik4-meta__timeline.tik4-meta__timeline
  margin-top: -0.5em

.tik4-event__timeline:after
  opacity: 0.4

.tik4-content-block.tik4-content-block
  margin-top: var(--t4-sp-4)

.tik4-se__m.tik4-se__m
  @apply text-primary uppercase


.tik4-sb.tik4-sb
  @apply bg-gray-200
  border: 0


a.tik4-button.tik4-button.tik4-button, button.tik4-button.tik4-button.tik4-button
  @apply uppercase

.tik4-meta__ts__date
  @apply overflow-hidden

.tik4-meta__ts__time__val
  @apply overflow-hidden