.overlay
  @apply fixed
  @apply inset-0
  z-index: 1000
  @apply text-bodyColor
  @apply normal-case

  .overlay-wrapper
    @apply flex flex-col items-center justify-center
    @apply min-h-screen h-full
    @apply px-2 md:px-4 py-8 md:py-4
    @apply text-center

    .overlay-backdrop-wrapper
      @apply fixed inset-0

      .overlay-backdrop
        @apply absolute inset-0
        @apply bg-gray-900 bg-opacity-95

        &.overlay-backdrop-red
          @apply bg-primary bg-opacity-90

    .overlay-hint
      @apply bg-dark text-white
      @apply p-4
      @apply mb-4
      @apply w-full md:max-w-screen-sm
      @apply shadow-xl
      @apply relative
      @apply rounded
      @apply text-left

    .overlay-content-wrapper
      @apply align-bottom
      @apply bg-gray-100
      @apply rounded
      @apply text-left
      @apply shadow-xl
      @apply transform
      @apply w-full md:max-w-screen-sm
      @apply p-4 md:px-8 md:py-6
      @apply mx-auto
      @apply h-full
      @apply flex flex-col

      .overlay-header
        @apply flex items-center justify-between
        @apply mb-4
        .headline
          @apply m-0
    
      .overlay-close
        @apply cursor-pointer
        @apply hover:scale-105

      .overlay-content
        @apply flex flex-col
        @apply h-full
        @apply overflow-y-hidden
        &.overlay-content-overflow
          @apply overflow-visible

  &.small
    .overlay-content-wrapper
      @apply h-auto md:h-auto
      @apply w-full md:w-auto
      @apply max-w-full
      @apply max-h-full

  &.overlay-borderless

    .overlay-content-wrapper
      @apply h-auto md:h-auto
      @apply p-0
      @apply relative
      @apply overflow-hidden
      @apply bg-transparent

      .overlay-header
        @apply absolute top-0 left-0 right-0
        @apply z-10
        @apply p-4

        .overlay-close
          @apply bg-white bg-opacity-50
          @apply rounded-full
    
  &.overlay--inline
    .overlay-wrapper
      @apply min-h-full
