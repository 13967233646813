.btn
  @apply py-2 px-5
  @apply rounded
  margin: 1px
  display: inline-flex
  align-items: center
  justify-content: center
  text-align: center
  text-transform: uppercase
  font-weight: 700
  @apply text-sm
  cursor: pointer
  user-select: none
  @screen md
    @apply py-2 px-10

  &:disabled
    cursor: auto

  svg
    @apply w-5 h-5
    @apply min-w-5 min-h-5

  &.btn-sm
    @apply py-2 px-8
    font-size: .8em
    svg
      width: 1.75em
      height: 1.75em
      min-width: 1.75em
      min-height: 1.75em

  &.btn-short
      @apply py-2 px-4

  &.btn-primary
    // @apply bg-primary
    @apply bg-gradient-hb
    @apply border border-primary
    @apply text-white
    @apply md:hover:bg-primary-dark md:hover:border-primary-dark
    // transition: background 1.3s ease, background-image 1.3s ease
    @screen md
      &:hover
        @apply bg-gradient-hb-transparent

  &.btn-hbnet
    background-image: linear-gradient(86deg, #BC270D 0%, #D64625 46%, #DC5E38 73%, #CC3A1C 94%)
    @apply border border-primary
    @apply text-white
    @apply md:hover:border-primary-dark
    transition: background-image 4.3s ease
    // container-type: inline-size
    &:hover
      background-image: linear-gradient(0deg, #CC3A1C 0%, #CC3A1C 46%, #CC3A1C 73%, #CC3A1C 94%)
    &:active
      background-image: linear-gradient(0deg, #a42710 0%, #a42710 46%, #a42710 73%, #a42710 94%)
  .btn-hbnet__logo
    @apply hidden
    @container (min-width: 8.5rem)
      @apply flex



  &.btn-gray
    @apply bg-gray-300 md:hover:text-current


  &.btn-white
    @apply bg-white
    @apply text-blue-dark
    @apply md:hover:bg-gray-200 md:hover:border-gray-200

  &.btn-underline
    @apply border-b-2 border-gray-300
    @apply px-0 py-2
    @apply rounded-none

  &.btn-primary-outline
    @apply bg-transparent
    @apply border border-primary md:hover:border-primary-dark
    transition: border .3s ease

  &.btn-transparent
    @apply bg-transparent

  &.btn-dark
    @apply bg-gray-900
    @apply text-white md:hover:text-gray-200

  &.btn-blue
    @apply bg-blue-dark
    @apply text-white md:hover:text-gray-200


  &.btn-icon-only
    @apply p-2
    svg
      @apply w-5 h-5
      @apply min-w-5 min-h-5
    &.btn-sm
      @apply p-1

  &.btn-no-padding
    @apply p-0

  &.btn-full
    flex: 1

  &.textXl
    @apply text-xl

  // You cannot `@apply` the `p-0` utility here because it creates a circular dependency.
  // &.p-0
  //   padding: 0

.btn-link
  @apply text-primary
  @apply text-left

.btn-group
  @apply flex
  @apply flex-col sm:flex-row
  @apply space-y-1 sm:space-y-0 sm:space-x-2
  @apply overflow-hidden
  .btn
    @apply w-full sm:w-auto

button
  &:focus
    outline: none !important


.ci-icon
  @apply bg-gradient-hb
  @apply border border-primary
  @apply text-white
  @apply p-0.5 rounded
.btn
  svg.ci-icon
    @apply w-[1.625rem] h-[1.625rem]
    @apply mr-1
@screen md
  .btn:hover .ci-icon
    @apply bg-primary-dark border-primary-dark
    @apply bg-gradient-hb-transparent
