.sub-nav
  @screen md
    @apply border-b border-gray-300
  .sub-nav-meta
    @apply mb-4
    .sub-nav-add
      @apply p-0
  .sub-nav-items
    .sub-nav-item
      @apply uppercase
      @apply py-3
      @screen md
        @apply pt-0 pb-3
      @apply border-b-4
      @apply border-transparent
      transition: border .3s ease, color .3s ease
      @apply hover:text-primary
      &:not(:last-child)
        @apply mr-6
        @screen md
        @apply mr-8
      &-active
        @apply border-primary
        @apply font-semibold
        color: $primary
