.kg-card
  iframe
    @apply w-full
    @apply h-96

.news-details
  img
    @apply w-full

  a,
  a strong,
  .md\:prose a
    @apply text-primary
    @apply md:hover:text-primary-light
    @apply transition-colors
    @apply underline