footer
  @apply bg-dark
  @apply text-white
  @apply w-full
  @apply absolute bottom-0 left-0 right-0
  ul
    li
      a
        @apply text-gray-400
        @apply transition-colors
        &:hover
          @apply text-white
          @apply opacity-100
  .link
    &.link-icon
      svg 
        margin-top: -0.24rem

.german-border
  background: linear-gradient(to left,yellow 0,yellow 33.3%,$primary 33.3%,$primary 66.6%,black 66.6%,black) bottom no-repeat
  background-size:100% 1px