.squad-item
  display: flex
  @apply md:flex-col
  position: relative
  .squad-image-wrapper
    position: relative
    @apply mb-3
    @apply w-1/3 md:w-full
    @apply h-40 md:h-40
    .squad-image
      width: 100%
      height: 100%
      @apply rounded-lg
      overflow: hidden
    .squad-number-wrapper
      position: absolute
      @apply -top-1 -right-1
      @apply w-8
      @apply h-8
      @apply bg-blue-dark
      @apply rounded-lg
      .squad-number
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        @apply text-primary
        font-weight: 600
  .squad-info-wrapper
    @apply w-2/3 md:w-full
    @apply pl-4 md:p-0
